import React from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';
import { getContrastingColor } from '../assets/js/utilities';
import Image from './Image';

import ListenWatchButton from './ListenWatchButton';
import ShareMobile from './ShareMobile';

const StoryHero = ({ data, url }) => {
  const {
    title,
    story_number,
    story_color,
    spotify_id,
    youtube_id,
    featured_image,
  } = data;

  // Get contrasting colors based on the background color
  const contrastingColor = getContrastingColor(story_color);
  const reverseContrastingColor = getContrastingColor(contrastingColor);

  return (
    <Wrapper className="full-width-container" backgroundColor={story_color}>
      <InnerWrapper>
        <Content>
          <StoryNumber contrastingColor={contrastingColor}>
            Nº {story_number}
          </StoryNumber>
          <Title contrastingColor={contrastingColor}>{title}</Title>

          <ButtonGroup>
            {spotify_id || youtube_id ? (
              <>
                <ListenWatchButton
                  story_color={story_color}
                  spotify_id={spotify_id}
                  contrastingColor={contrastingColor}
                  reverseContrastingColor={reverseContrastingColor}
                />

                <ListenWatchButton
                  youtube_id={youtube_id}
                  contrastingColor={contrastingColor}
                  reverseContrastingColor={reverseContrastingColor}
                />
              </>
            ) : null}

            <ShareMobile
              url={url}
              title={title}
              contrastingColor={contrastingColor}
              reverseContrastingColor={reverseContrastingColor}
            />
          </ButtonGroup>
        </Content>

        <ImageWrapper>
          <Image image={featured_image} aspectRatio={1 / 1} loading="eager" />
        </ImageWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default StoryHero;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  display: block;
  padding-top: 8.8rem;
  background-color: ${props => props.backgroundColor};
`;

const InnerWrapper = styled.div`
  width: 100%;
  max-width: var(--width-max);
  margin: 0 auto;
  padding: var(--space-l) var(--page-padding);
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (${bp.min.sm}) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: var(--space-xs);

  @media (${bp.min.sm}) {
    padding-right: var(--space-m);
  }

  @media (${bp.min.sm_md}) {
    max-width: 80rem;
    margin-top: 0;
    margin-right: var(--space-xl);
  }
`;

const StoryNumber = styled.div`
  font-size: clamp(4.4rem, 5.5vw, 8.4rem);
  color: ${props => props.contrastingColor};
  line-height: 0.99;
  letter-spacing: -0.05em;
  margin-bottom: var(--space-xxs);

  @media (${bp.min.sm}) {
    margin-bottom: 0;
    font-weight: var(--font-weight-bold);
  }
`;

const Title = styled.h1`
  font-size: clamp(4.4rem, 5.5vw, 8.4rem);
  line-height: 1.1;
  letter-spacing: -0.05em;
  color: ${props => props.contrastingColor};

  @media (${bp.min.sm}) {
    line-height: 0.99;
    font-weight: var(--font-weight-bold);
  }
`;

const ButtonGroup = styled.div`
  margin-top: var(--space-xl);
  display: flex;
`;

const ImageWrapper = styled.div`
  display: flex;
  margin-bottom: var(--space-xs);

  @media (${bp.min.sm}) {
    max-width: 60rem;
    margin-bottom: 0;
  }
`;
