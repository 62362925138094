import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import bp from '../../assets/js/breakpoints';

import Logo from '../Logo';
import Link from '../Link';

import michiganScript from '../../images/michigan-script.svg';
import michiganVeteransLogo from '../../images/michigan-veteran-connector-logo.png';

const FooterDesktop = () => {
  const staticData = useStaticQuery(graphql`
    {
      prismicGlobalLayout {
        _previewable
        data {
          footer_menu {
            link_text
            link_url {
              link_type
              target
              url
            }
          }
          footer_contact {
            title
            email
          }
          privacy_policy {
            link_type
            target
            url
          }
          partners_title
          partners {
            link_text
            link_url {
              link_type
              target
              url
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.prismicGlobalLayout;
  const {
    footer_menu,
    footer_contact,
    privacy_policy,
    partners_title,
    partners,
  } = document.data;

  return (
    <FooterWrapper>
      <MichiganScript src={michiganScript} alt="Michigan Logo" />

      <FooterLinks>
        <Grid>
          <Column columnsSmMd="1 / 5" columnsLg="1 / 4">
            <Link url={{ url: '/', link_type: 'Document' }}>
              <Logo fill="var(--color-snow)" />
            </Link>
          </Column>

          <Column columnsSmMd="5 / 7" columnsLg="4 / 7">
            <ul>
              {footer_menu.map(item => (
                <li key={item.link_text}>
                  <Link url={item.link_url}>{item.link_text}</Link>
                </li>
              ))}
            </ul>
          </Column>

          <Column columnsSmMd="7 / 10" columnsLg="7 / 10">
            <p>{footer_contact[0].title}</p>
            <a href={`mailto:${footer_contact[0].email}`}>
              {footer_contact[0].email}
            </a>
          </Column>

          <Column columnsSmMd="10 / 12" columnsLg="10 / 12">
            <Link url={privacy_policy}>Privacy Policy</Link>
            <p>&copy; {new Date().getFullYear()} Hey Bay City</p>
          </Column>

          <Column
            columnsSmMd="12 / 13"
            columnsLg="12 / 13"
            style={{ display: 'flex' }}
          >
            <MichiganVeteransLogo
              src={michiganVeteransLogo}
              alt="Michigan Veteran Connector Logo"
            />
          </Column>
        </Grid>
      </FooterLinks>

      <Partnerships className="full-width-container">
        <Grid>
          <Column columnsSmMd="1 / 5" columnsLg="1 / 4">
            <p>{partners_title}</p>
          </Column>

          <Column columnsSmMd="5 / 13" columnsLg="4 / 12">
            <Partners>
              {partners.map(item => (
                <li key={item.link_text}>
                  <Link url={item.link_url}>{item.link_text}</Link>
                </li>
              ))}
            </Partners>
          </Column>
        </Grid>
      </Partnerships>
    </FooterWrapper>
  );
};

export default FooterDesktop;

/**
 * Styled Components
 *
 */
const FooterWrapper = styled.footer`
  position: sticky;
  bottom: 0;
  z-index: 0;
  background-color: var(--color-navy);
  color: var(--color-snow);
  padding: 4.8rem var(--page-padding) 0;
  display: none;

  @media (${bp.min.sm_md}) {
    display: block;
  }

  a {
    color: var(--color-snow);

    &:hover {
      color: var(--color-great-blue);
    }
  }
`;

const MichiganScript = styled.img`
  width: 100%;
  max-width: var(--width-max);
  margin: 0 auto 8.8rem;
  display: block;
`;

const MichiganVeteransLogo = styled.img`
  width: 100%;
  max-width: 6rem;
  margin-left: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 var(--space-m);
  width: 100%;
  max-width: var(--width-max);
  margin: 0 auto;
`;

const Column = styled.div`
  @media (${bp.min.sm_md}) {
    grid-column: ${props => props.columnsSmMd};
  }

  @media (${bp.min.lg}) {
    grid-column: ${props => props.columnsLg};
  }
`;

const FooterLinks = styled.div`
  font-size: 1.6rem;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  font-weight: var(--font-weight-bold);

  .logo {
    margin-top: 0.4rem;

    &:hover {
      fill: var(--color-great-blue);
    }
  }
`;

const Partnerships = styled.div`
  background-color: #0d1a21;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  padding: var(--space-m) var(--page-padding);
  margin-top: var(--space-xl);

  p {
    color: var(--color-baybee-blue);
  }
`;

const Partners = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: space-between;
`;
