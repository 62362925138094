import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import bp from '../../assets/js/breakpoints';

import RecentStoriesSliderDesktop from './RecentStoriesSliderDesktop';
import RecentStoriesSliderMobile from './RecentStoriesSliderMobile';

const RecentStoriesSlider = ({ variant }) => {
  const staticData = useStaticQuery(graphql`
    {
      prismicHomepage {
        _previewable
        data {
          recent_stories_header
        }
      }
      allPrismicStory(
        sort: { fields: first_publication_date, order: DESC }
        skip: 1
        limit: 6
      ) {
        edges {
          node {
            _previewable
            ...StoryCard
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.allPrismicStory;
  const stories = document.edges;

  return (
    <Wrapper variant={variant}>
      <Title variant={variant}>
        {data.prismicHomepage.data.recent_stories_header}
      </Title>

      <RecentStoriesSliderDesktop stories={stories} />
      <RecentStoriesSliderMobile stories={stories} />
    </Wrapper>
  );
};

export default RecentStoriesSlider;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  margin-top: 6rem;

  ${props =>
    props.variant === 'footer' &&
    `
    margin-top: 2rem;
    padding-bottom: var(--space-xxl);

    @media (${bp.min.xs_sm}) {
      border-bottom: .1rem solid var(--color-navy);
      margin-top: 6rem;
    }
  `}
`;

const Title = styled.h2`
  ${props =>
    props.variant === 'footer'
      ? `
    font-size: 4.4rem;
    line-height: 1;
    letter-spacing: -0.1rem;
    text-align: center;
    margin-bottom: var(--space-xl);
    text-transform: uppercase;

    @media (${bp.min.sm}) {
      font-size: 2.2rem;
      font-weight: var(--font-weight-bold);
      margin-bottom: var(--space-xl);
      text-align: left;
      text-transform: initial;
    }
  `
      : `
    font-size: clamp(3.6rem, 2.048rem + 6.62vw, 9rem);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: -0.1rem;
    text-align: center;
    margin-bottom: var(--space-xl);

    @media (${bp.min.sm}) {
      margin-bottom: var(--space-xxl);
    }
  `}

  ${props =>
    props.variant === 'no-title' &&
    `
   display: none;
  `}
`;
